import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Sacops from "../../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import NavMenu from "../../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../../components/public/others/NavbarCM";
import Footer from "../../../../../components/public/others/Footer";
import ListSacop from "../../../../../components/public/transparency/sacop/List/padrao/ListAll";
import Loading from "../../../../../components/public/others/Loading";
import Search from "../../../../../components/public/others/ordinances/Utils";
import { TipoPortal } from "../../../../../services/variables";
import SacopListTypeAndSubtype from "../../../../../components/public/transparency/sacop/subTypes/sacopListTypeAndSubtype";
import ShowDocuments from "../../../../../components/public/others/ordinances/ShowResult";
import IntencaoDeContratar from "./item-intencao";

export default function ListCardSacop({ name }) {
  const [listResult, setListResult] = useState(null);
  const [intencaoData, setIntencaoData] = useState([]);
  const [type, setType] = useState(null);
  const [nameTypeDeclaracao, setNameTypeDeclaracao] = useState(null);
  const [route, setRoute] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sacopData = Object.values(Sacops).find(
      (item) => item?.TITLE === name
    );
    if (sacopData) {
      setType(sacopData.SELECT);
      setRoute(sacopData.ROUTE);
      setNameTypeDeclaracao(sacopData["SELECT-TYPE-DECLARACAO"] ?? null);
    }
  }, [name]);

  useEffect(() => {
    if (!type) return;

    const fetchData = async () => {
      try {
        const { data } = await api.get(`/sacop/list/all/${type}`);
        if (!data.err && data.res.length) {
          setListResult(formatData(data.res));
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    if (type === "avisos") {
      fetchIntencaoData();
    }
  }, [type]);

  const fetchIntencaoData = async () => {
    try {
      const { data } = await api.get(`/sacop/intencao-de-licitar`);
      if (!data.err && data.res.length) {
        setIntencaoData(formatData(data.res));
      }
    } catch (error) {
      console.error("Erro ao buscar intenção de contratar:", error);
    }
  };

  const formatData = (data) => {
    return data.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        let value = item[key];

        if (key.toUpperCase().includes("DATA")) {
          value = new Date(value).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
          });
        }

        if (key.toUpperCase().includes("CNPJ") && value) {
          value = value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
        }

        if (key.toUpperCase().includes("VALOR") && typeof value === "number") {
          value = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }

        return { ...acc, [key]: value };
      }, {});
    });
  };

  return (
    <>
      {TipoPortal === 3 ? <NavMenu /> : <NavMenuCM />}

      <div className="section-title rotes_pages">
        <h6>{route}</h6>
      </div>

      {type === "avisos" && intencaoData.length > 0 && (
        <IntencaoDeContratar name="Avisos" data={intencaoData} />
      )}

      <div className="section-title-portarias">
        <h4>{name}</h4>
      </div>

      {name === "Obras Públicas" && (
        <ShowDocuments name="Obras Paralisadas" select="Obras Públicas" />
      )}

      {name === "Edital e Licitações" && (
        <ShowDocuments
          name="Plano anual de contratações"
          select="Plano anual de contratações"
        />
      )}

      {nameTypeDeclaracao && (
        <SacopListTypeAndSubtype
          type={nameTypeDeclaracao}
          subType="Declarações"
        />
      )}

      {listResult ? (
        <ListSacop name={name} data={listResult} />
      ) : loading ? (
        <Loading />
      ) : (
        <Search />
      )}

      {!listResult && !loading && (
        <div className="section-title">
          <h4>Não há dados para serem exibidos</h4>
        </div>
      )}

      <Footer />
    </>
  );
}
